
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { closeSDK, initSDK } from 'Store/v2/KYC';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';

interface Methods {
    goBack: () => void;
}

interface Computed {
    needForceKYC: boolean;
    isKycInProgress: boolean;
    isThemeDark: boolean;
    isKycVerified: boolean;
    currentUser: InternalUserResponse | undefined;
}

type NoData = Record<string, never>;

export default Vue.extend<NoData, Methods, Computed>({
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        isKycInProgress() {
            return this.$store.state.KYC.isKycInProgress;
        },
        needForceKYC() {
            return this.currentUser?.type === 'subuser'
                && !this.isKycVerified;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
    },
    methods: {
        async goBack() {
            await this.$store.dispatch(closeSDK());
        },
    },
    async mounted() {
        if (!this.isKycInProgress && this.currentUser) {
            await this.$store.dispatch(initSDK(this.needForceKYC ? true : undefined));
        }
    },
    watch: {
        async isThemeDark() {
            await this.$store.dispatch(initSDK(this.needForceKYC ? true : undefined));
        },
        async currentUser(value) {
            if (value && !this.isKycInProgress) {
                await this.$store.dispatch(initSDK(this.needForceKYC ? true : undefined));
            }
        },
    },
});
