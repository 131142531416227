import { render, staticRenderFns } from "./KYC.vue?vue&type=template&id=6212589d&"
import script from "./KYC.vue?vue&type=script&lang=ts&"
export * from "./KYC.vue?vue&type=script&lang=ts&"
import style0 from "./KYC.vue?vue&type=style&index=0&id=6212589d&prod&lang=postcss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports